.showimg {
  max-width: 400px;
  max-height: 400px;
  width: 100%;
  margin: 10px auto;
  display: block;
}

.imagediv{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faf4f4;
  margin: 0.4em; /* ラベルとテキスト間の余白を指定する */
}

