@import url(https://fonts.googleapis.com/earlyaccess/roundedmplus1c.css);
@charset "UTF-8";
@font-face {
    font-family: 'Noto Sans Japanese';
    font-style: normal;
    font-weight: 400;
    src: url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff2) format('woff2'), url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.woff) format('woff'), url(//fonts.gstatic.com/ea/notosansjapanese/v3/NotoSansJP-Regular.otf) format('opentype');
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Rounded Mplus 1c', sans-serif;
    /* font-family: "Comic Sans MS", Meiryo, "メイリオ", "Open Sans", "Helvetica Neue", Helvetica, Arial, "ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", sans-serif; */
    font-weight: 600;
    margin: 0px;
    /* margin-bottom: 0.4rem; */
}

.App {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AppHeader {
  width: 100%;
  /* background-color: aqua; */
  background: #404040;
  color: #FFCCCC;
  padding: 6px;
}

.AppBody{
  width: 100%;
  text-align: center;
  background-color: #faf4f4;
  min-height: 100vh;
  font-size: calc(10px + 2vmin);
  color: rgb(22, 22, 22);
}

.AppHeader {
  width: 100%;
  /* background-color: aqua; */
  background: #404040;
  color: #FFCCCC;
  padding: 6px;
}
label {
  color: rgb(255, 255, 255); /* ラベルテキストの色を指定する */
  font-size: 0.8em; /* ラベルテキストのサイズを指定する */
  background-color: #006DD9;/* ラベルの背景色を指定する */
  padding: 0.4em;
  padding-top: 0.3em;
  margin: 0.4em; /* ラベルとテキスト間の余白を指定する */
  border-radius: 10px; /* ラベルボックスの角丸を指定する */
}

label > input {
   /* アップロードボタンのスタイルを無効にする */
  display:none;
}

.upgraded-button{
  padding: 5px 20px;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid rgb(0, 0, 0);
  color: rgb(0, 0, 0);
  pointer-events: none;
}

.btn {
  display: inline-block;
  font-size: 0.8em;
  padding: 0.2em 0.4em;
  margin: 0.4em;
  /* height: 32px; */
  text-decoration: none;
  background-color: white;
  border-radius: 3px;
  -webkit-transition: .4s;
  transition: .4s;
}

.btn_disc {
  color: #eb7311;
  border: solid 2px #eb7311;
}

.btn_disc:hover {
  background: #eb7311;
  color: white;
}

.btn_judge {
  color: #67c5ff;
  border: solid 2px #67c5ff;
}

.btn_judge:hover {
  background: #67c5ff;
  color: white;
}

.btn_cansel {
  color: #ff67c5;
  border: solid 2px #ff67c5;
}

.btn_cansel:hover {
  background: #ff67c5;
  color: white;
}

.btn_calc {
  background: #068621;
  border: solid 2px #068621;
  color: white;
}

.btn_calc:hover {
  background: #FFFFFF;
  color: #068621;
  border: solid 2px #068621;
}

.btn_done {
  background: #6e6e6e;
  color: white;
  border: solid 2px #6e6e6e;
}
.showimg {
  max-width: 400px;
  max-height: 400px;
  width: 100%;
  margin: 10px auto;
  display: block;
}

.imagediv{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #faf4f4;
  margin: 0.4em; /* ラベルとテキスト間の余白を指定する */
}


body {
  width: 100%;
  margin: 0px;
  padding: 0px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

